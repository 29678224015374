import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-generic-webview',
  templateUrl: './generic-webview.component.html',
  styleUrls: ['./generic-webview.component.css']
})
export class GenericWebviewComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private domSanitizer: DomSanitizer,
  ) { }

  view: string;
  cta: string;
  redirectLink: string = null;
  pageUrl: string = null;
  ctaUrl: string = null;
  genericPageConfig: string;
  error = false;
  pdf: string;
  pdfUrl: string;
  safePdfUrl : SafeResourceUrl;
  ngOnInit() {
    try {
      this.genericPageConfig = JSON.parse(environment.genericPageConfig);
      this.error = false;
      this.route.queryParams.subscribe(params => {
          if (params) {
            this.pdf = params.pdf || "";
            this.view = params.view || "";
            this.cta = params.cta || "";
            if (this.view && this.genericPageConfig && this.genericPageConfig[this.view]) {
              this.pageUrl = this.genericPageConfig[this.view].pageUrl || this.pageUrl;
              this.ctaUrl = this.genericPageConfig[this.view].cta || this.ctaUrl;
              this.redirectLink = this.genericPageConfig[this.view].redirectLink || this.redirectLink;
            }
            if(this.genericPageConfig && this.genericPageConfig[this.pdf]){
              this.pdfUrl = this.genericPageConfig[this.pdf] && this.genericPageConfig[this.pdf].pdfUrl
            }
          } 
          
          if (!params || !this.view || !this.pageUrl ) {
            this.error = true;
          }
          if(this.pdfUrl){
            this.safePdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl)
            this.error = false;
          }
        }
      );
    } catch (e) {
      console.error('Error : ', e);
      this.error = true;
    }
  }
  ctaClicked() {
    if (this.redirectLink && !this.error) {
      window.open(this.redirectLink, '_blank');
    } else if (this.ctaUrl && !this.error) {
      this.router.navigate(['/page'], { queryParams: { view: this.ctaUrl } });
    }
  }
}
